/* src/components/CardMockup.css */
.card-mockup {
    width: 320px;
    height: 190px;
    background: linear-gradient(135deg, #1e3a8a, #2563eb);
    color: white;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: 'Quicksand', sans-serif;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bank-name {
    font-size: 18px;
    font-weight: bold;
}

.card-number {
    font-size: 20px;
    letter-spacing: 3px;
    margin: 15px 0;
}

.card-info {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.card-holder,
.card-expiry {
    display: flex;
    flex-direction: column;
}